





































import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { AxiosInstance } from 'axios'

export default defineComponent({
  props: {
    value: {
      type: Object,
      required: false,
      default: () => ({})
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    rules: {
      type: Array,
      required: false,
      default: () => []
    },
    vehicles: {
      type: Array,
      required: false,
      default: () => []
    }
  },

  setup(props, { root, emit }) {
    const entries = ref<any[]>(props.vehicles)
    const isLoading = ref(false)
    const model = ref(props.value)
    const search = ref(null)
    const count = ref(0)

    const fields = computed(() => {
      if (!model.value) return []

      return Object.keys(model.value as any).map(key => ({ key, value: (model.value as any)[key] || 'n/a' }))
    })
    const items = computed(() => entries.value)

    watch(() => search.value, val => {
      if (isLoading.value) return

      if (val) {
        isLoading.value = true

        const axiosInstance = root.$store.getters['api/getInstance'] as AxiosInstance

        axiosInstance
          .get('vehicle', { params: { filter: { search: val } } })
          .then(({ data }) => {
            const { vehicles, total } = data
            count.value = total
            const ids = new Set(entries.value.map(e => e.id))
            entries.value = [...entries.value, ...vehicles.filter((v: any) => !ids.has(v.id))]
          })
          .catch(error => console.log(error))
          .finally(() => isLoading.value = false)
      }
    })

    watch(() => model.value, val => emit('input', val))

    const valueComparator = (a: any, b: any) => a && b && a.id === b.id

    const filter = (item: any, query: string) => (
      item?.details?.registrationNumber?.toLowerCase().includes(query.toLowerCase()) ||
      item?.driver?.firstName?.toLowerCase().includes(query.toLowerCase()) ||
      item?.driver?.lastName?.toLowerCase().includes(query.toLowerCase()) ||
      item?.company?.name?.toLowerCase().includes(query.toLowerCase()) ||
      item?.comment?.text?.toLowerCase().includes(query.toLowerCase())
    )

    const getInfo = (item: any) => {
      const info = []

      if (item.driver) info.push({ icon: 'mdi-account', text: `${item.driver.firstName} ${item.driver.lastName}` })
      if (item.company) info.push({ icon: 'mdi-domain', text: item.company.name })
      if (item.comment) info.push({ icon: 'mdi-comment', text: item.comment.text })

      return info
    }

    return { entries, isLoading, model, search, fields, items, valueComparator, filter, getInfo }
  }
})
